import React from "react";
import {Link} from "react-router-dom";
import {maskDate} from "../../Utils/tools";
import UserActions from "./UserActions";
import {useRecoilValue} from "recoil";
import {cloudinaryUrl} from "../../Recoil/atoms";

export default function Users({users}){

    const url = useRecoilValue(cloudinaryUrl);

    return(
        <>
            {users.length > 0 ?
                <div className="grid3 laptop:grid2 mobile:grid1 col-gap row-gap">
                    {
                        users.map(({id, firstName, lastName, createdAt, profilePhoto, email, restaurantId, blocked, status, redFlag, userPhoto}) => {
                            return (
                                <div
                                      className="b b-grey300 t-black flex br-half"
                                      id={id} key={id}>
                                    <Link to={`/user/${id}`} className="shrinks-0 w-150px max-w-150px overflow-hidden grey300 relative flex align-end">
                                        <img className="edges absolute obj-cover top-0 bottom-0 right-0 w-100 h-100" src={userPhoto ? `${url}${userPhoto}.jpg` : profilePhoto?.url}/>
                                        {redFlag &&
                                        <div className="f-small lh-100 center pver-half w-100 inline-flex align-center justify-center z-1000 relative red f-700 t-white">
                                            ♦️ Caution, contact manager
                                        </div>
                                        }
                                    </Link>
                                    <div className="p flex align-center grows">
                                        <div>
                                            <div className="mb-half flex">
                                                <h4 className="h5 f-500 pb grows">
                                                    <Link to={`/user/${id}`} className="t-black">
                                                    {firstName || 'No Name'} {lastName}
                                                    </Link>
                                                </h4>
                                                <div>
                                                    <UserActions u={{id : id, blocked : blocked, status : status, email : email}} />
                                                </div>
                                            </div>
                                            <div className="mb-half">
                                                <p>
                                                    {restaurantId}
                                                    {firstName?.charAt(0)}{lastName?.charAt(0)}
                                                </p>
                                            </div>
                                            <div className="mb-half">
                                                <p>
                                                    <strong>Joined on:</strong> {maskDate(createdAt)}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="f-small">
                                                    {status?.approved ? "✅ Approved" : "❌ Not Approved"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                :
                <h4 className="center">
                    No users found
                </h4>
            }
        </>
    )
}
