import React, {useState} from "react";
import {maskDate} from "../../Utils/tools";
import Button from "../UI/Button";
import Textarea from "../UI/Textarea";
import {createNote} from "../../Recoil/api/data";

export default function NotesForm({user, host, switchNotePop, setLoading}){

    const [noteText, setNoteText] = useState("");
    const [checked, setChecked] = React.useState(false);

    const saveNote = async () => {

        await createNote({
            user : user,
            host : host,
            note : noteText,
            redFlag: checked
        });
        switchNotePop();
        setLoading(true);
    }

    const handleChange = () => {
        setChecked(!checked);
    };

    return(
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90" onClick={switchNotePop}>

            </div>
            <div className="relative z-100 white max-w-400px w-100 p-2 br mobile:mhor">
                <div className="pb mb b-bottom b-grey300 flex">
                    <h5 className="grows center">Create Note</h5>
                </div>
                <Textarea label="Note" value={noteText} onChange={(e)=>{setNoteText(e.target.value)}} />
                <div className="mver">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                    /> {checked && <span>♦️ </span>}Add red flag to this note
                </div>

                <div className="flex align-center justify-center direction-column">

                        <Button onClick={saveNote} fontSize="f-small" className="relative phor-2 hover:white hover:t-gold transition-5 w-100 mb" height="h-3rem">
                            Save Note
                        </Button>
                        <Button onClick={switchNotePop} fontSize="f-small" color="black" className="relative phor-2 hover:white hover:b-black hover:t-black transition-5 w-100" height="h-3rem">
                            Cancel
                        </Button>
                </div>

            </div>
        </div>
    )
}
