import axios from "axios";
import {url} from "../atoms";

export const getGenders = async () => {

    try {
        const res = await axios.get(`${url}genders`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}




export const getHobbies = async () => {

    try {
        const res = await axios.get(`${url}hobbies`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const getMaritalStatuses = async () => {

    try {
        const res = await axios.get(`${url}marital-statuses`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getSpirits = async () => {

    try {
        const res = await axios.get(`${url}drinks`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getReferrals = async (email) => {

    let e = encodeURIComponent(email);

    try {
        const res = await axios.get(
            `${url}referals?email=${e}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            }
        );
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const createCheckin = async (data) => {

    try {
        const res = await axios.post(
            `${url}checkins`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const getCheckins = async (u) => {

    try {
        const res = await axios.get(
            `${url}checkins?user=${u}&_sort=createdAt:DESC`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            }
        );
        return (res.data);
    }
    catch (e){
        throw e
    }

}


export const getNotes = async (u) => {

    try {
        const res = await axios.get(
            `${url}notes?user=${u}&_sort=createdAt:DESC`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            }
        );
        return (res.data);
    }
    catch (e){
        throw e
    }

}

export const getRedNotes = async (u) => {

    try {
        const res = await axios.get(
            `${url}notes?user=${u}&redFlag=true&_sort=createdAt:DESC`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            }
        );
        return (res.data);
    }
    catch (e){
        throw e
    }

}

export const createNote = async (data) => {

    try {
        const res = await axios.post(
            `${url}notes`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}

export const createInvitation = async (data) => {

    try {
        const res = await axios.post(
            `${url}invitations`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}

export const createAdmin = async (data) => {

    let d = {...data};
    d.username = data.email;

    try {
        const res = await axios.post(`${url}auth/local/register`, d, {withCredentials : true});
        return (res);
    }
    catch (e){
        throw e
    }

}

export const sms = async (data) => {
    console.log("Sending", data.phone);
    try {
        const res = await axios.get(
            `https://thepark-cms.herokuapp.com/send-sms/${data.phone}/${data.id}`
        );
        console.log("Data for SMS", res.data);
        return (res.data);
    }
    catch (e){
        throw e
    }
}

export const smsPost = async (data) => {
    console.log("Sending", data.phone);
    try {
        const res = await axios.post(
            `https://thepark-cms.herokuapp.com/send-sms`,
            data
        );
        return (res.data);
    }
    catch (e){
        throw e
    }
}

export const updateInvitation = async (id,data) => {

    try {
        const res = await axios.put(
            `${url}invitations/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}


export const updateCampaign = async (id,data) => {

    try {
        const res = await axios.put(
            `${url}campaigns/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}


export const getCampaign = async (id) => {

    try {
        const res = await axios.get(
            `${url}campaigns/${id}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}


export const getInvitations = async (par) => {


    let params = {
        _sort : "createdAt:DESC",
        completed : false
    };
    if(par?.type){
        params.type = par.type;
    }
    if(par?.sent){
        params.sent = par.sent === "yes" ? true : false;
    }
    if(par?.invited){
        switch (par.invited){
            case "host":
                params.host_null = false
                break;
            case "guest":
                params.guest_null = false
        }
    }

    if(par?.email){
        params.email = par.email
    }

    console.log("PARAMS", par);

    try {
        const res = await axios.get(
            `${url}invitations`,
            {
                withCredentials : true,
                params : params,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const getInvitationTypes = async () => {

    try {
        const res = await axios.get(
            `${url}invitation-types`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const deleteInvitation = async (id) => {
    try {
        const res = await axios.delete(
            `${url}invitations/${id}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}



export const updateUser = async (id, data) => {
    try {
        const res = await axios.put(
            `${url}users/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}

export const updateUserStatus = async (id, data) => {

    let d = {approved : data};

    try {
        const res = await axios.put(
            `${url}user-statuses/${id}`,
            d,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}

export const deleteUser = async (id) => {
    try {
        const res = await axios.delete(
            `${url}users/${id}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}


