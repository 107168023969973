import React from "react";

export default function IconFb({className, color}){
    return(
        <svg width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M10.5701 11.9923H7.30871V24H2.43129V11.9923H0V7.86071H2.43129V5.38488C2.43129 2.01158 3.8101 0 7.72384 0H10.9704V4.1315H8.9394C7.45692 4.1315 7.32354 4.70405 7.32354 5.78722V7.86071H10.9704L10.5701 11.9923Z" className={color ? color : 'black'}/>
        </svg>


    )
}
