import React, {useEffect, useState} from "react";
import Button from "../UI/Button";
import {getCheckins} from "../../Recoil/api/data";
import {maskDate, maskHours} from "../../Utils/tools";

export default function TabCheckins({user, loading, setLoading}){

    const [checkins, setCheckins] = useState([]);

    useEffect(async ()=>{
        if(loading){
            try{
                const res = await getCheckins(user);
                setCheckins(res);
                setLoading(false);
            }
            catch (e){
                console.log(e);
            }
        }
    },[loading])


    return(
        loading ?
            <div>
                <h6>
                    Loading...
                </h6>
            </div>
        :
            <div className="w-100">
                {checkins.length > 0 ? checkins.map(
                    ({createdAt, host, id})=>{
                        return(
                            <div key={id} className="w-100 flex align-center pver b-bottom b-grey300 hover:grey100">
                                <div className="grows justify-start flex no-wrap mobile:f-small f-700">
                                    {maskDate(createdAt)}
                                </div>
                                <div className="mr-4">
                                    Host: {host.firstName}
                                </div>
                                <Button className="no-wrap">
                                    {maskHours(createdAt)}
                                </Button>
                            </div>
                        )
                    }
                    )
                    :
                    <div>
                        <h6>
                            This user has no checkins yet
                        </h6>
                    </div>
                }
            </div>
    )
}
