import React, {useEffect, useState} from "react";
import Button from "../UI/Button";
import {getCheckins, getNotes} from "../../Recoil/api/data";
import {maskDate, maskHours} from "../../Utils/tools";

export default function TabNotes({user, loading, setLoading, switchNotePop}){

    const [notes, setNotes] = useState([]);

    useEffect(async ()=>{
        if(loading){
            try{
                const res = await getNotes(user);
                setNotes(res);
                setLoading(false);
            }
            catch (e){
                console.log(e);
            }
        }
    },[loading])


    return(
        loading ?
            <div>
                <h6>
                    Loading...
                </h6>
            </div>
        :
            <div className="w-100">
                <Button className="mb" height="h-2rem" onClick={switchNotePop}>
                    + Create note
                </Button>
                {notes.length > 0 ? notes.map(
                    ({createdAt, host, id, note, redFlag})=>{
                        return(
                            <div key={id} className="w-100 flex align-center pver b-bottom b-grey300 hover:grey100">
                                <div className="grows no-wrap mobile:f-small left wrap max-w-300px w-300px">
                                    <b className="f-700">{maskDate(createdAt)}</b><br />
                                    {redFlag && <b className="t-red">♦️ Red Flag Comment</b>}
                                    <p className="do-wrap break-word pr ">{note}</p>
                                </div>
                                <div className="mr-4 grows">
                                    Host: {host.firstName}
                                </div>
                                <div className="right">
                                    <Button className="no-wrap">
                                        {maskHours(createdAt)}
                                    </Button>
                                </div>

                            </div>
                        )
                    }
                    )
                    :
                    <div>
                        <h6>
                            This user has no notes yet
                        </h6>
                    </div>
                }
            </div>
    )
}
