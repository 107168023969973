import React, {useEffect, useState} from "react";
import Button from "../Components/UI/Button";
import InvitationForm from "../Components/Invitations/InvitationForm";
import {deleteInvitation, getInvitations, getInvitationTypes, sms, updateInvitation} from "../Recoil/api/data";
import IconIn from "../Components/UI/Icons/IconIn";
import IconIg from "../Components/UI/Icons/IconIg";
import Select from "../Components/UI/Select";
import InvitationBulk from "../Components/Invitations/InvitationBulk";
import {useRecoilValue} from "recoil";
import {rolesAtom, userAtom} from "../Recoil/atoms";
import InvitationTemplate from "../Components/Invitations/InvitationTemplate";

export default function Invitations(){

    const user = useRecoilValue(userAtom);
    const roles = useRecoilValue(rolesAtom);
    const [pop, setPop] = useState(false);
    const [popTemplate, setPopTemplate] = useState(false);
    const [popBulk, setPopBulk] = useState(false);
    const [loading, setLoading] = useState(true);
    const [referals, setReferals] = useState([]);
    const [invTypes, setInvTypes] = useState([]);
    const [params, setParams] = useState({
        type : "",
        sent : "",
        invited : ""
    });

    useEffect(async ()=>{
        const res = await getInvitations(params);
        setReferals(res);
    },[params])

    const changeSelect = (e) => {
        setParams({...params, [e.target.name] : e.target.value})
    }

    useEffect(async ()=>{
        if(loading){
            const res = await getInvitations();
            setReferals(res);
            const res2 = await getInvitationTypes();
            setInvTypes(res2);
            setLoading(false);

        }
    }, [loading]);

    const del = async (id)=>{
        try {
            const res = await deleteInvitation(id);
            setLoading(true);
        }
        catch (e){
            console.log(e);
        }
    }

    const reSend = async (ref)=>{
        await updateInvitation(ref.id, {sent : true});
        //cioIdentify(ref)
        //cioEvent(ref.email, "sendSMS");
        const smsSend = await sms(ref);
        console.log("SMS", smsSend);
        setLoading(true);
    }

    return(
        <div className="grows white mobile:pt-2">

            <div className="p-2 mobile:p">
                <div className="mb-4 mobile:mb-2 flex align-center mobile:block">
                    <h4 className="mr-2">
                       Invitations
                    </h4>
                    <div className="mobile:mt">
                        <Button color="black" onClick={()=>{setPop(!pop)}}>
                            Invite User
                        </Button>

                        {user?.role?.id === '615502d4ef399c6c60fc5479' &&

                            <Button className="ml" onClick={() => {
                                setPopBulk(!popBulk)
                            }}>
                                Bulk Import
                            </Button>
                        }
                    </div>
                    {user?.role?.id === '615502d4ef399c6c60fc5479' &&
                        <div className="flex grows justify-end">
                            <Button color="black" onClick={() => {
                                setPopTemplate(!popTemplate)
                            }}>
                                Edit SMS template
                            </Button>
                        </div>
                    }

                </div>

                <div className="pb b-bottom b-grey200 mb flex mobile:grid2 mobile:row-gap">

                    <Select
                        label="Approved?"
                        unselected="All"
                        options={invTypes}
                        className="mr"
                        value={params.type}
                        name="type"
                        onChange={changeSelect}
                    />

                    <Select
                        label="SMS?"
                        unselected="Any Status"
                        options={[{id : "yes", title : "Sent"}, {id : "no", title : "Not Sent"}]}
                        className="mr"
                        value={params.sent}
                        name="sent"
                        onChange={changeSelect}
                    />

                    <Select
                        label="Invited by"
                        unselected="Any Type of User"
                        options={[{id : "host", title : "Admin"}, {id : "guest", title : "Referral"}]}
                        className="mr"
                        name="invited"
                        onChange={changeSelect}
                    />

                </div>

                <div className="w-100 mobile:overflow-x-scroll">
                    {loading ?
                        <div>Loading...</div>
                    :
                        <table>
                            <thead>
                                <tr className="b-bottom b-grey300 t-black f-uppercase f-small left f-small">
                                    <th className="pver">Name</th>
                                    <th className="pver">Email</th>
                                    <th className="pver">Invited By</th>
                                    <th className="pver">Phone</th>
                                    <th className="pver">Type</th>
                                    <th className="pver">Social</th>
                                    <th className="pver mobile:hidden">Industry</th>
                                    <th className="pver">SMS sent</th>
                                    <th className="pver">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="f-small">
                            {referals?.map((ref)=>{
                                let date = new Date(ref.updatedAt);
                                return(
                                    <tr key={ref.id} className="b-bottom b-grey300 t-black">
                                        <td className="pver pr">
                                            {ref.firstName} {ref.lastName}
                                        </td>
                                        <td className="pver pr">
                                            {ref.email}
                                        </td>
                                        <td className="pver mobile:hidden pr">
                                            {ref.guest &&
                                                <div>
                                                    <span className="f-uppercase f-bold f-small">GUEST REFERRAL:</span><br />
                                                    {ref.guest.firstName} {ref.guest.lastName}
                                                </div>
                                            }

                                            {ref.host &&
                                                <div>
                                                    <span className="f-uppercase f-bold f-small">
                                                        {roles[ref.host.role]}:
                                                    </span> <br />
                                                    {ref.host.firstName}
                                                </div>
                                            }

                                        </td>
                                        <td className="pver">
                                            +1{ref.phone}
                                        </td>
                                        <td className="pver">
                                            {ref.type.label}
                                        </td>
                                        <td className="pver center flex">
                                            {ref.linkedin &&
                                                <a className="ml-half t-black mver-half pver-half b-bottom b-grey300 flex align-center justify-center"  target="_blank" href={`https://linkedin.com/${ref.linkedin}`}>
                                                    <IconIn className="ml-half w-1halfrem" />
                                                </a>
                                            }
                                            {ref.instagram &&
                                                <a className="ml-half t-black mver-half pver-half b-bottom b-grey300 flex align-center justify-center"  target="_blank" href={`https://instagram.com/${ref.instagram}`}>
                                                    <IconIg className="ml-half w-1halfrem" />
                                                </a>
                                            }
                                        </td>
                                        <td className="pver mobile:hidden">
                                            {ref.industry}
                                        </td>

                                        <td className="pver pr">
                                            {ref.sent ?
                                                <>
                                                    YES  <br />{date.toLocaleDateString()} <br /> <strong>{date.toLocaleTimeString()}</strong>
                                                </>
                                                :
                                                "NO"
                                            }



                                        </td>
                                        <td className="pver">
                                            <Button className="mr-half"
                                                    height="h-2rem"
                                                    fontSize="f-small"
                                                    color="gold"
                                                    onClick={()=>{
                                                        reSend(ref)
                                                    }}
                                            >
                                                Send SMS
                                            </Button>
                                            {/*{user?.role?.id === '615502d4ef399c6c60fc5479' &&
                                                <Button height="h-2rem" fontSize="f-small" color="black"
                                                        onClick={() => {
                                                            del(ref.id)
                                                        }}>
                                                    Delete
                                                </Button>
                                            }*/}
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    }
                </div>

                {pop &&
                    <InvitationForm types={invTypes} setPop={setPop} setLoading={setLoading} />
                }
                {popBulk &&
                    <InvitationBulk types={invTypes} setPop={setPopBulk} setLoading={setLoading} />
                }
                {popTemplate &&
                    <InvitationTemplate setPop={setPopTemplate} />
                }
            </div>
        </div>
    )
}
