import React from "react";
import {useRecoilValueLoadable} from "recoil";
import {genders, interests} from "../../Recoil/selectors";
import Select from "../UI/Select";

export default function Hobbies({onSelect, value}) {

    const values = useRecoilValueLoadable(interests);

    switch (values.state) {
        case 'hasValue':
            return (
                <Select className="mb mr" label="Hobbies" name="hobbies" value={value} options={values.contents} onChange={onSelect} />
            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error Loading</div>
            )
    }
}
