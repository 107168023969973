import React from "react";
import Title from "../UI/Title";
import Button from "../UI/Button";
import {useRecoilValue} from "recoil";
import {cloudinaryUrl, userAtom} from "../../Recoil/atoms";

export default function GuestHead({user, tab, changeTab, switchRedFlag}){

    const url = useRecoilValue(cloudinaryUrl);

    return(
        <>
            <div className="max-w-400px grey200 br-2 mb mhor-auto overflow-hidden mobile:mhor mobile:mb-2 relative">

                <div className="absolute p-half black br top-1 right-1 z-1000 relative f-700 t-white f-small">

                    Guest {user.idChecked ? "" : "Not"} Verified
                </div>


                <img src={user?.userPhoto ? `${url}${user.userPhoto}.jpg` : user?.profilePhoto?.url} className="w-100 obj-cover obj-center aspect1-1" />
                {user.redFlag &&
                    <div className="center pver-half w-100 inline-flex align-center justify-center z-1000 relative red f-700 t-white h6">
                        ♦️ Caution, contact manager
                    </div>
                }
            </div>

            <div>
                <Button height="h-3rem" fontSize="f-medium" className="mb" to={`/user/${user.id}/checkin`} type="Link">
                    Checkin Guest
                </Button>
            </div>




            {user.redFlag ?
                <Button height="h-2rem" color="white" fontColor="black" className="mb-2 b-black" onClick={switchRedFlag}>
                    Remove caution
                </Button>
                :
                <Button height="h-2rem" color="white" fontColor="black" className="mb-2 b-black" onClick={switchRedFlag}>
                    Red flag this guest
                </Button>
            }
            <Title title={`${user?.firstName} ${user?.lastName}`} light={true} />

            {user.status?.approved &&
                <div className="center t-grey700 f-small mb-2 pb b-bottom b-grey300">

                    <>
                        {user.invitingUser &&
                            <>
                                <b className="f-uppercase">Invited by: </b>
                                {user.invitingUser?.firstName} &nbsp;|&nbsp;
                            </>
                        }

                        <b className="f-uppercase">Approved by: </b>
                        {user.approvedBy ? user.approvedBy : "Admin"}
                    </>

                </div>
            }

            <div className="phor flex b-bottom b-grey300 f-1rem mobile:f-small f-uppercase f-700 mb-4 justify-center">
                <a className={`block pver phor ${tab === "profile" && 'black t-white'}`}  onClick={()=> {changeTab('profile')}}>
                    Profile
                </a>
                <a className={`block pver phor ${tab === "checkins" && 'black t-white'}`} onClick={()=> {changeTab('checkins')}}>
                    Checkins
                </a>
                <a className={`block pver phor ${tab === "notes" && 'black t-white'}`} onClick={()=> {changeTab('notes')}}>
                    Notes
                </a>
            </div>
        </>
    )
}
