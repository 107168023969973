import React from "react";

export default function IconX({classname, color}){
    return(
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={classname}>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.25005 8.45712L13.6465 14.8536L14.3536 14.1465L7.95715 7.75001L14.3536 1.35358L13.6465 0.646469L7.25005 7.04291L0.853591 0.646454L0.146484 1.35356L6.54294 7.75001L0.146484 14.1465L0.853591 14.8536L7.25005 8.45712Z" className={color ? color : 'black'} />
        </svg>

    )
}
