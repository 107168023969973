import React from "react";

export default function IconIg({className, color}){
    return(
        <svg width="25" height="25" viewBox="0 0 25 25" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9793 5.74999C24.9965 5.08523 24.898 4.42256 24.6879 3.79165C24.3145 2.68722 23.5971 1.73136 22.6411 1.06405C21.6852 0.396742 20.5407 0.0530599 19.3753 0.0833221H6.62501C5.98128 0.0748823 5.34079 0.173445 4.72936 0.374987C3.61388 0.739944 2.64719 1.45733 1.97464 2.41919C1.30209 3.38105 0.959987 4.53533 1.00012 5.70831C1.00012 9.87498 1.00012 14.1875 1.00012 18.4167C0.99167 19.0672 1.08998 19.7147 1.29156 20.3333C1.64534 21.4418 2.35207 22.4041 3.30368 23.0736C4.2553 23.7432 5.39965 24.0831 6.56245 24.0417H19.3961C20.2879 24.06 21.1705 23.8587 21.9662 23.4554C22.7619 23.0521 23.446 22.4593 23.9585 21.7292C24.6556 20.7524 25.014 19.5745 24.9793 18.375C24.9793 16.2917 24.9793 14.2083 24.9793 12.125C24.9793 10.0417 25.0001 7.83332 24.9793 5.74999ZM12.9793 19.6041C10.988 19.5877 9.08356 18.7865 7.6794 17.3746C6.27523 15.9626 5.4845 14.0538 5.47908 12.0625C5.49548 10.0748 6.29379 8.17356 7.70127 6.76998C9.10875 5.3664 11.0124 4.57336 13.0001 4.56248C14.9931 4.57349 16.9008 5.37292 18.3061 6.78606C19.7115 8.19921 20.5004 10.1112 20.5003 12.1042C20.4676 14.079 19.6611 15.9622 18.2548 17.349C16.8484 18.7358 14.9544 19.5156 12.9793 19.5208V19.6041ZM20.7501 6.16665C20.2582 6.14505 19.7939 5.93295 19.4556 5.57525C19.1174 5.21754 18.932 4.74227 18.9378 4.24999C18.9488 3.74529 19.158 3.26527 19.5207 2.91421C19.8835 2.56315 20.3704 2.3694 20.8752 2.375C21.3726 2.39123 21.844 2.60028 22.19 2.95798C22.536 3.31568 22.7294 3.79398 22.7291 4.29166C22.6919 4.78712 22.4658 5.24933 22.0974 5.58278C21.7291 5.91623 21.2468 6.09541 20.7501 6.08333V6.16665Z" className={color ? color : 'black'}/>
            <path d="M13 7.08331C11.696 7.08331 10.4456 7.60131 9.52356 8.52336C8.60151 9.44542 8.08313 10.696 8.08313 12C8.08313 12.6456 8.21039 13.285 8.45748 13.8815C8.70456 14.478 9.067 15.02 9.52356 15.4766C9.98011 15.9332 10.5221 16.2953 11.1186 16.5424C11.7151 16.7895 12.3544 16.9166 13 16.9166C13.6457 16.9166 14.2849 16.7895 14.8814 16.5424C15.4779 16.2953 16.0199 15.9332 16.4765 15.4766C16.933 15.02 17.2955 14.478 17.5426 13.8815C17.7896 13.285 17.9169 12.6456 17.9169 12C17.9169 10.696 17.3985 9.44542 16.4765 8.52336C15.5544 7.60131 14.304 7.08331 13 7.08331Z" className={color ? color : 'black'} />
        </svg>


    )
}
