import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import Button from "../UI/Button";
import IconX from "../UI/Icons/IconX";
import Textarea from "../UI/Textarea";
import {getCampaign, updateCampaign} from "../../Recoil/api/data";
import UploadPicture from "./UploadPicture";

export default function InvitationTemplate({setPop}){

    const user = useRecoilValue(userAtom);
    let u = {...user};
    const templateId = '62abd13dba5966716fe8a0b8'

    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)

    const [error, setError] = useState("")
    const [sms, setSMS] = useState(
        {
            "Message": ""
        }
    );

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setSMS({...sms, [name] : val});
    }

    const saveTemplate = async () => {
        try{
            const res = await updateCampaign(templateId, {Message: sms.Message});
            setPop(false);
        }
        catch (e){
            console.log("SMS TEMPLATE, ERROR", e);
        }
    }

    useEffect(async ()=>{
        if(loading){
            let campaign = await getCampaign(templateId);
            setSMS({...sms, Message: campaign.Message, Photo: campaign.Photo});
            setLoading(false);
        }
    },[loading])

    return(
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90">

            </div>
            <div className="relative z-100 white max-w-400px w-100 p-2 br">
                <div className="right">
                    <a onClick={()=>setPop(false)}>
                        <IconX />
                    </a>
                </div>
                <div className="pb mb b-bottom b-grey300 flex">
                    <h5 className="grows center">SMS Template</h5>
                </div>
                <div>
                    <div className='mb'>
                        {!loading &&
                            <UploadPicture cId={templateId} image={sms.Photo?.url} />
                        }
                    </div>

                    <Textarea light={true} label="SMS" className="mobile:mb w-100" name="Message" value={sms.Message} onChange={changeText} />
                    <p className='t-grey700 f-bold f-italic f-small mver'>Link is automatically generated</p>


                    <div className="flex justify-center">
                        <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveTemplate}>
                            Save Template
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
