import React, {useState} from "react";
import Button from "../UI/Button";
import {createInvitation, getInvitations, sms} from "../../Recoil/api/data";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {cioEvent, cioIdentify} from "../../CIO/cio";

export default function InvitationRun({invites, types, setLoading, setPop}){

    const user = useRecoilValue(userAtom);
    let u = {...user};

    const [imported, setImported] = useState("pending");


    const runImport = () => {
        setImported("loading");
        let arr = [];

        invites.map(async (inv, i) => {
            arr[i] =  saveInvite(inv);
        });

        Promise.all(arr).then( (values) => {
            console.log("All promises completed", values);
            setImported("completed");
        });

    }

    const saveInvite = async (inv) => {

        try {
            const res = await getInvitations({email: inv.email});
            if(res.length < 2){
                const invitation = {
                    "firstName": inv.firstName,
                    "lastName": inv.lastName,
                    "email": inv.email,
                    "phone": inv.phone,
                    "host": u.id,
                    "sent" : true
                }

                const type = types.find((e)=>{
                    return e.approved === JSON.parse(inv.preapproved.toLowerCase());
                });
                invitation.type = type.id;

                const res = await createInvitation(invitation);

                //cioIdentify(res);
                //cioEvent(invitation.email, "sendSMS");
                const smsSend = await sms(res);
                console.log("SMS", smsSend);

                return invitation;
            }
        }
        catch (e) {
            console.log("Not found", inv);
        }

    }

    const finish = () => {
        setLoading(true);
        setPop(false);
    }

    return(
        <div>
            {imported==="pending" ?
                <>
                    {invites.length > 0 &&
                        <Button className="mt" onClick={runImport}>Run Import</Button>
                    }
                </>
                :
                imported === "loading" ?
                <h6 className="mt-2">
                    Uploading Invitations...
                </h6>
                :
                <div className="mt-2">
                    <h6>CSV Imported Successfully! </h6>
                    <Button className="mt" onClick={finish}>Finish</Button>
                </div>
            }

            {/*{imported.length > 0 &&
                <h4>Users imported: {imported.length} / {invites.length}</h4>
            }*/}

        </div>
    )
}