import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useRecoilState, useRecoilValue} from 'recoil'
import {getUsers} from "../Recoil/api/user";
import {loadingUsers, userAtom, usersFiltersParams} from "../Recoil/atoms";
import Users from "../Components/Users/Users";
import UsersTable from "../Components/Users/UsersTable";
import ExportCSV from "../Components/Users/ExportCSV";
import UsersFilters from "../Components/Users/UsersFilters";

export default function Home (){

    let history = useHistory();
    const user = useRecoilValue(userAtom);
    const filters = useRecoilValue(usersFiltersParams);
    const [loading, setLoading] = useRecoilState(loadingUsers);

    const [mode, setMode] = useState("grid");
    const [users, setUsers] = useState([]);

    useEffect(async ()=>{
        if(loading){
            let params = {...filters};
            loadUsers(params);
        }
    },[loading]);

    const loadUsers = async (params)=>{
        try {
            //order, name, code
            const res = await getUsers(params);
            setUsers(res.data);
            setLoading(false);
        }
        catch (e){
            if(e.response?.status === 403){
                console.log("Clearing Storage")
                window.localStorage.clear()
                window.location="/"
            }

        }
    }


    return(
        <div className="grows white mobile:pt-2">
            {user.id ? null: history.push('/login')}

                <div className="p-2 mobile:p">
                    <div className="mb-4 mobile:mb-2 flex align-center mobile:block">
                        <div className="flex align-center">
                            <h4>
                                Search Users
                            </h4>
                            {user?.role?.id === '615502d4ef399c6c60fc5479' &&
                                <ExportCSV users={users} />
                            }
                        </div>
                        <div className="grows flex justify-end align-center mobile:hidden">
                            View as:
                            <div>
                                <a className={`ml mobile:ml-0 pver-half phor f-700 f-small b b-black ${mode === "grid" ? "black t-white": ""}`}
                                   onClick={()=>setMode("grid")}>
                                    GRID
                                </a>
                                <a className={`pver-half phor f-700 f-small b b-black ${mode === "table" ? "black t-white": ""}`}
                                   onClick={()=>setMode("table")}>
                                    LIST
                                </a>
                            </div>
                        </div>
                    </div>

                    <UsersFilters setLoading={setLoading} />


                    <div className="">
                        {loading ?
                            <div>Loading...</div>
                           :
                            <>
                                {mode === "grid" && <Users users={users}/>}
                                {mode === "table" && <UsersTable users={users} />}
                            </>

                        }
                    </div>

                </div>


        </div>
    )
}
