import React, {useEffect, useState} from "react";
import Button from "../UI/Button";
import {CSVLink} from "react-csv";

export default function ExportCSV({users}){

    const [usersData, setUsersData] = useState([]);

    useEffect(async ()=>{
        console.log("CSV", users[0]);
        if(users.length > 0){
            let ud = users?.map(({approvedBy, gender, birthday, invitingUser, status, favoriteDrink, otherHobbies, facebook, instagram, linkedin, twitter, address, city, state, zipCode, companyName, industry, firstName, phoneNumber, createdAt, lastName, email, blocked, profileCompleted, redFlag, idChecked})=>{
                return {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    status : status?.approved ? "Approved" : "Not Approved",
                    blocked	: blocked,
                    profileCompleted: profileCompleted,
                    redFlag: redFlag,
                    idVerified: idChecked,
                    phoneNumber: "+1" + phoneNumber,
                    address: address,
                    city: city,
                    gender : gender?.label,
                    joinedOn : createdAt.toLocaleString(),
                    state: state,
                    zipCode: zipCode,
                    companyName: companyName,
                    industry: industry,
                    favoriteDrink: favoriteDrink,
                    otherHobbies: otherHobbies,
                    facebook: facebook,
                    instagram: instagram,
                    linkedin: linkedin,
                    twitter: twitter,
                    birthday: birthday?.toLocaleString(),
                    invitedBy: invitingUser?.firstName,
                    approvedBy: approvedBy

                }
            })
            setUsersData(ud)
        }
    },[users]);

    return(

        <CSVLink data={usersData} filename="TheParkUsers.csv">
            <Button type="button" className="ml grows no-wrap" color="gold">
                Export to CSV
            </Button>
        </CSVLink>

    )
}