import React, {useState} from "react";
import Button from "../UI/Button";
import {uploadPhoto} from "../../Recoil/api/user";

export default function UploadPicture({image, cId}){

	console.log("IMAGE", image)

	const [photo, setPhoto] = useState(image ? image : "" );
	const [uploading, setUploading] = useState(false);

	const savePicture = async (e) =>{
		setUploading(true);
		const file = e.target.files[0];
		let formData = new FormData();
		formData.append('files', file);
		formData.append('ref', 'campaigns');
		formData.append('refId', cId);
		formData.append('field', 'Photo');
		//formData.append('source', 'users-permissions');

		try {
			const res = await uploadPhoto(formData);
			//setUser({...user, profilePhoto : res.data[0].related[0].profilePhoto});
			setPhoto(res.data[0].url);
			setUploading(false);
		}
		catch (e){
			console.log(e);
		}
	}

	return(
		<div className="mb-2 center">
			<div className="h-250px w-100 mb mhor-auto overflow-hidden flex">
				{uploading ?
					<p className="phor grows flex align-center justify-center">
						Uploading Photo...
					</p>
					:
					<>
						{photo ?
							<img src={photo} className="obj-contain grows"  />
							:
							<p className="phor grows flex align-center justify-center">
								Please upload a photo
							</p>

						}
					</>
				}
			</div>


			<Button fontSize="f-small" color='black' className="relative phor-2 transition-5" height="h-2rem">
				Upload Photo
				<input type="file" onChange={savePicture} className="absolute edges w-100 h-100 op-0" />
			</Button>

		</div>
	)
}
