import React from "react";
import IconIn from "../UI/Icons/IconIn";
import IconFb from "../UI/Icons/IconFb";
import IconTt from "../UI/Icons/IconTt";
import IconIg from "../UI/Icons/IconIg";
import {maskDate} from "../../Utils/tools";
import {updateUser} from "../../Recoil/api/user";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import EditUser from "../Form/EditUser";
import GuestMainData from "./GuestMainData";

export default function TabProfile({user, setUser, tab}){

    const u = useRecoilValue(userAtom);

    if(u.role.id !== '624ee7faed5731c01c29bf86'){
        return (
            <EditUser user={user} setUser={setUser} />
        )
    }


    return(
        <div>

            <GuestMainData user={user} />

            <div className="flex pver-2 b-top b-grey300">
                <h6 className="f-500 f-playfair f-uppercase">
                    Social Media
                </h6>
                <div className="grows f-bold right grid2">
                    {user?.linkedin &&
                    <a className="ml t-black mver-half pver-half b-bottom b-grey300 flex align-center justify-end"  target="_blank" href={`https://linkedin.com/${user?.linkedin.replace("@", "")}`}>
                        /{user?.linkedin.replace("@", "")} <IconIn className="ml-half" />
                    </a>
                    }
                    {user?.facebook &&
                    <a className="ml t-black mver-half pver-half b-bottom b-grey300 flex align-center justify-end"  target="_blank" href={`https://facebook.com/${user?.facebook.replace("@", "")}`}>
                        /{user?.facebook.replace("@", "")} <IconFb className="ml-half" />
                    </a>
                    }
                    {user?.twitter &&
                    <a className="ml t-black mver-half pver-half b-bottom b-grey300 flex justify-end"  target="_blank" href={`https://twitter.com/${user?.twitter.replace("@", "")}`}>
                        /{user?.twitter.replace("@", "")} <IconTt className="ml-half" />
                    </a>
                    }
                    {user?.instagram &&
                    <a className="ml t-black mver-half pver-half flex justify-end b-bottom b-grey300"  target="_blank" href={`https://instagram.com/${user?.instagram.replace("@", "")}`}>
                        /{user?.instagram.replace("@", "")} <IconIg className="ml-half" />
                    </a>
                    }
                </div>
            </div>
            <div className="flex pver-2 b-top b-grey300">

                <div className="grid2 mver-2 w-100">
                    <div className="b-right b-grey300">
                        <h6 className="f-500 mb-half f-playfair f-uppercase">
                            Favorite Dish
                        </h6>
                        <p>
                            {user?.favoriteDishes}
                        </p>
                    </div>
                    <div>
                        <h6 className="f-500 mb-half f-playfair f-uppercase">
                            Favorite Drink
                        </h6>
                        <p>
                            {user?.favoriteDrink}
                        </p>
                    </div>


                </div>

            </div>

            <div className="flex pver-2 b-top b-grey300">
                <h6 className="f-500 mb-half f-playfair f-uppercase">
                    Hobbies
                </h6>
                <div className="grows flex wrap justify-end">
                    {user?.hobbies.map(
                        ({title, id}, i)=>{
                            return(
                                <span key={id}>
                                    {title}{ i+1 < user?.hobbies.length ? ',' : ''} &nbsp;
                                </span>
                            )
                        }
                    )}
                </div>
            </div>

            <div className="flex pver-2 b-top b-grey300">
                <h6 className="f-500 mb-half f-playfair f-uppercase">
                    Favorite Spirits
                </h6>
                <div className="grows flex wrap justify-end">
                    {user?.favoriteSpirits.map(
                        ({title, id}, i)=>{
                            return(
                                <span key={id}>
                                    {title}{ i+1 < user?.favoriteSpirits.length ? ',' : ''} &nbsp;
                                </span>
                            )
                        }
                    )}
                </div>
            </div>

            <div className="flex pver-2 mb-2 b-top b-grey300">

                <div className="grid2 mver-2 w-100">
                    <div className="b-right b-grey300">
                        <h6 className="f-500 mb-half f-playfair f-uppercase">
                            Industry
                        </h6>
                        <p>
                            {user?.industry}
                        </p>
                    </div>
                    <div>
                        <h6 className="f-500 mb-half f-playfair f-uppercase">
                            Title
                        </h6>
                        <p>
                            {user?.jobTitle}
                        </p>
                    </div>


                </div>

            </div>
        </div>
    )
}
