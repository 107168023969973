import React from "react";

import logo from './UI/Styles/img/logo-white.png'
import Button from "./UI/Button";
import {Link} from "react-router-dom";

export default function Header(){

    return(
        <header className="flex justify-center">
            <div className="max-w-1056px pver-2 b-bottom b-grey900 grows flex justify-center relative mobile:justify-start mobile:phor">
                <Link to="/">
                    <img src={logo} alt="The Park Logo" className="h-4rem w-auto" />
                </Link>


                <div className="absolute right-0 h-4rem flex justify-end align-center mobile:right-1">
                    <Button type="Link" to="/login" className="phor-2 hover:black hover:t-white transition-5 b-white ml" height="h-2rem" color="white" fontColor="t-black">
                        Login
                    </Button>
                </div>

            </div>

        </header>
    )
}
