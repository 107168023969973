import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {createInvitation, getCampaign, sms, smsPost} from "../../Recoil/api/data";
import Input from "../UI/Input";
import Button from "../UI/Button";
import IconX from "../UI/Icons/IconX";
import Error from "../UI/Error";
import Select from "../UI/Select";

export default function InvitationForm({setPop, types, setLoading}){

    const user = useRecoilValue(userAtom);
    let u = {...user};

    const templateId = '62abd13dba5966716fe8a0b8'


    const [error, setError] = useState("")
    const [refer, setRefer] = useState(
        {
            "firstName": "",
            "lastName": "",
            "email": "",
            "phone": "",
            "type" : "",
            "host": u.id,
            "sent" : true
        }
    );

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setRefer({...refer, [name] : val});
    }

    const saveReferral = async () => {

        if(!refer.firstName){
            setError("Please add First Name")
            return
        }
        if(!refer.lastName){
            setError("Please add Last Name")
            return
        }
        if(!refer.email){
            setError("Please add Email")
            return
        }
        if(!refer.phone){
            setError("Please add Phone Number")
            return
        }

        if(!refer.type){
            setError("Please select an invitation type")
            return
        }

        try{
            const campaign = await getCampaign(templateId);
            const res = await createInvitation(refer);
            setLoading(true);
            setPop(false);
            //cioIdentify(res);
            //cioEvent(refer.email, "sendSMS");

            let data = {
                phone : res.phone,
                token : res.id,
                message : campaign.Message,
                photo : campaign.Photo.url
            }
            console.log(data);
            const smsSend = await smsPost(data);
            console.log("SMS", smsSend);
        }
        catch (e){
            //throw e
            console.log("SMS, ERROR", e);
        }
    }

    const changeSelect = (e) => {
        setRefer({...refer, [e.target.name] : e.target.value})
    }

    return(
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90">

            </div>
            <div className="relative z-100 white max-w-400px w-100 p-2 br">
                <div className="right">
                    <a onClick={()=>setPop(false)}>
                        <IconX />
                    </a>
                </div>
                <div className="pb mb b-bottom b-grey300 flex">
                    <h5 className="grows center">Send Invitation</h5>
                </div>
                <div>
                    <div className="mb grid2 col-gap mobile:block mobile:mb">
                        <Input light={true} label="First Name" className="mobile:mb" name="firstName" value={refer.firstName} onChange={changeText} />
                        <Input light={true} label="Last Name" name="lastName" value={refer.lastName} onChange={changeText} />
                    </div>

                    <Input light label="Email" className="mb" name="email" value={refer.email} onChange={changeText} />
                    <Input light label="Phone Number" className="mb" name="phone" value={refer.phone} onChange={changeText} />

                    <Select
                        label="Invitation Type"
                        options={types}
                        className="mb"
                        value={refer.type}
                        name="type"
                        onChange={changeSelect}
                    />

                    {error &&
                        <Error className="mt-2 mb f-1rem t-red" msg={error} />
                    }

                    <div className="flex justify-center">
                        <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveReferral}>
                            Save Invitation
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
