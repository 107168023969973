import React, {useEffect, useState} from "react";
import {Route, Switch, useParams, Link, useHistory, useLocation} from 'react-router-dom'
import {getUser} from "../Recoil/api/user";
import {useRecoilState, useRecoilValue} from "recoil";
import {loginRedirectAtom, userAtom} from "../Recoil/atoms";
import {createCheckin, updateUser} from "../Recoil/api/data";
import GuestHead from "../Components/Guest/GuestHead";
import TabProfile from "../Components/Guest/TabProfile";
import TabCheckins from "../Components/Guest/TabCheckins";
import Checkin from "../Components/Guest/Checkin";
import TabNotes from "../Components/Guest/TabNotes";
import NotesForm from "../Components/Guest/NotesForm";

export default function Guest(){

    let {id} = useParams();
    let history = useHistory();
    let location = useLocation();

    const [loginRedirect, setLoginRedirect] = useRecoilState(loginRedirectAtom);
    const [loading, setLoading] = useState(true);
    const [loadingC, setLoadingC] = useState(true);
    const [loadingN, setLoadingN] = useState(true);
    const [notePop, setNotePop] = useState(false);
    const [user, setUser] = useState({});
    const [tab, setTab] = useState("profile");
    const u = useRecoilValue(userAtom);

    useEffect(async ()=>{
        if(loading){
            try{
                const cl = await getUser(id);
                setUser(cl.data);
                setLoading(false);
            }
            catch (e){
                setLoginRedirect(location.pathname);
                //window.localStorage.clear();

            }
        }
    },[loading]);

    useEffect(()=>{
        window.redirect = loginRedirect;
        if(loginRedirect === location.pathname){
            history.push("/");
        }

    },[loginRedirect]);



    const checkin = async (user) => {
        let data = {
            "user": user,
            "host": u.id
        }

        try{
            const res = await createCheckin(data);
            setTab('checkins');
            setLoadingC(true);
            history.push(`/user/${user}`)
        }
        catch (e){
            console.log(e);
        }

    }

    const changeTab = (tab) => {
        if (tab==='checkins')
            setLoadingC(true)

        if (tab==='notes') {
            console.log("notes tab");
            setLoadingN(true)
        }
        setTab(tab);
    }

    const switchRedFlag = async () => {

        let redFlag = user.redFlag || false;
        const res = await updateUser(user.id,{redFlag : !redFlag});
        setLoading(true);

    }

    const switchNotePop = () => {

        console.log("NOTE POP", notePop);
        setNotePop(!notePop);

    }

    return(
        <div className="flex grows white">
            {loading ?
                <div>
                    Loading ... {location.pathname}
                </div>
            :
                <div className="grows">
                    <div className="max-w-600px mhor-auto center pver-3 mobile:pver w-100 mobile:p">

                        <GuestHead user={user} tab={tab} changeTab={changeTab} switchRedFlag={switchRedFlag} />

                        {tab === "profile" &&
                            <TabProfile user={user} setUser={setUser} />
                        }

                        {tab === "checkins" &&
                            <TabCheckins user={user.id} loading={loadingC} setLoading={setLoadingC} />
                        }

                        {tab === "notes" &&
                            <TabNotes user={user.id} loading={loadingN} setLoading={setLoadingN} switchNotePop={switchNotePop} />
                        }

                    </div>

                    <Switch>
                        <Route exact path="/user/:id/:checkin">
                            <Checkin user={user} setUser={setUser} checkin={checkin} />
                        </Route>
                    </Switch>

                    {notePop &&
                        <NotesForm user={user.id} host={u.id} switchNotePop={switchNotePop} setLoading={setLoadingN} />
                    }
                </div>
            }

        </div>

    )
}
