import React, {useState} from 'react'

export default function Textarea({label, children, className, width, ...props}){

    const [defaultClass] = useState(`f-400 h-100px f-montserrat p b b-grey500 br-half f-1rem ${width ? width : "w-100"}`)

    return(
        <div className={className}>
            {label &&
                <label className="f-small mb-half block f-700">{label}</label>
            }
            <div className="relative">
                <textarea className={defaultClass} placeholder={label} {...props} />
                {children && children}
            </div>

        </div>
    )

}
