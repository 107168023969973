export const  maskDate = (date)=>{
    if(date){
        let d = new Date(date);
        return (`${d.getMonth() + 1} / ${d.getDate()} / ${d.getFullYear()}`)
    }
};

export const  maskHours = (date)=>{
    if(date){
        let d = new Date(date);
        return (`${d.getHours()} : ${d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes()}`)
    }
};
