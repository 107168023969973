import React from "react";
import {useRecoilValueLoadable} from "recoil";
import {admins} from "../../Recoil/selectors";
import Select from "../UI/Select";

export default function InviterAdmins({onSelect, value}) {

	const values = useRecoilValueLoadable(admins);

	switch (values.state) {
		case 'hasValue':
			console.log("ADMINS", values.contents)
			return (
				<Select className="mb mr" label="Invited By" name="invitingUser" value={value} options={values.contents} onChange={onSelect} />
			)
		case 'loading':
			return <div>Loading...</div>
		case 'hasError':
			return (
				<div>Error Loading</div>
			)
	}
}
