import React from "react";
import {Link} from "react-router-dom";
import {maskDate} from "../../Utils/tools";
import UserActions from "./UserActions";
import {useRecoilValue} from "recoil";
import {cloudinaryUrl} from "../../Recoil/atoms";

export default function UsersTable({users}){

    const url = useRecoilValue(cloudinaryUrl);

    return(
        <>
            {users.length > 0 ?
                <table>
                    <thead>
                        <tr className="b-bottom b-grey300 t-black f-uppercase f-small">
                            <th className="left pver">
                                Photo
                            </th>
                            <th className="left">
                                Name
                            </th>
                            <th className="left">
                                Joined on
                            </th>
                            <th className="left mobile:hidden">
                                Birthday
                            </th>
                            <th className="left mobile:hidden">
                                Job Title
                            </th>
                            <th className="left mobile:hidden">
                                Favorite Drink
                            </th>
                            <th className="left mobile:hidden">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        users.map(({id, status, firstName, lastName, createdAt, profilePhoto, restaurantId, birthday, jobTitle, favoriteDrink, blocked, confirmed, redFlag, userPhoto}) => {
                            return (
                                <tr to={`/user/${id}`}
                                      className="b-bottom b-grey300 t-black"
                                      id={id} key={id}>
                                    <td className="shrinks-0 w-100px max-w-100px overflow-hidden grey300 relative grows">
                                        <Link to={`/user/${id}`} className="shrinks-0 w-100px h-150px overflow-hidden grey300 relative grows flex align-end">
                                            <img className="edges absolute obj-cover top-0 bottom-0 right-0 w-100 h-100" src={userPhoto ? `${url}${userPhoto}.jpg` : profilePhoto?.url} />
                                            {redFlag &&
                                            <div className="center pver-half w-100 inline-flex align-center justify-center z-1000 relative red f-700 t-white f-small">
                                                ♦️ Caution, contact manager
                                            </div>
                                            }
                                        </Link>
                                    </td>
                                    <td className="p flex align-center">
                                        <div>
                                            <div className="mb-half">
                                                <Link to={`/user/${id}`} className="t-black">
                                                    <h5 className="h5 f-500 ">
                                                        {firstName || 'No Name'} {lastName}
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className="mb-half">
                                                <p>
                                                    {restaurantId}
                                                    {firstName?.charAt(0)}{lastName?.charAt(0)}
                                                </p>
                                                <p className="f-small">
                                                    {blocked ? "🚫 Blocked" : ""}
                                                </p>
                                                <p className="f-small">
                                                    {status.approved ? "✅ Approved" : "❌ Declined"}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="mb-half">
                                        <p>
                                            {maskDate(createdAt)}
                                        </p>
                                    </td>
                                    <td className="mb-half mobile:hidden">
                                        <p>
                                            {maskDate(birthday)}
                                        </p>
                                    </td>
                                    <td className="mb-half mobile:hidden">
                                        <p>
                                            {jobTitle}
                                        </p>
                                    </td>
                                    <td className="mb-half mobile:hidden">
                                        <p>
                                            {favoriteDrink}
                                        </p>
                                    </td>
                                    <td>
                                        <UserActions u={{id : id, blocked : blocked, status : status}} />
                                    </td>


                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
                :
                <h4 className="center">
                    No users found
                </h4>
            }
        </>
    )
}
