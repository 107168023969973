import {maskDate} from "../../Utils/tools";
import IconIn from "../UI/Icons/IconIn";
import IconFb from "../UI/Icons/IconFb";
import IconTt from "../UI/Icons/IconTt";
import IconIg from "../UI/Icons/IconIg";
import React from "react";
import {updateUser} from "../../Recoil/api/user";
import GuestMainData from "../Guest/GuestMainData";
import Input from "../UI/Input";
import Button from "../UI/Button";

export default function EditUser({user, setUser}){

	const save = async () => {

		let profile = {...user};
		delete profile.role;
		delete profile.status;
		delete profile.profilePhoto;
		delete profile.gender;
		delete profile.hobbies;
		delete profile.favoriteSpirits;

		console.log(profile);

		try {
			//await updateUser(user.id, {...user});
			//setUser({...user, idChecked : checked})
		}
		catch (e){
			console.log(e);
		}
	}

	const changeText = (e) => {
		setUser({...user, [e.target.name] : e.target.value})
	}

	return(
		<div>
			<GuestMainData user={user} />

			<div className="grid2 mver-2 w-100 col-gap">
				<div>
					<h6 className="f-500 mb-half f-playfair f-uppercase">
						First Name
					</h6>
					<div>
						<Input placeholder='First Name' value={user?.firstName } name='firstName' onChange={changeText} />
					</div>
				</div>
				<div>
					<h6 className="f-500 mb-half f-playfair f-uppercase">
						Last Name
					</h6>
					<div>
						<Input placeholder='Last Name' value={user?.lastName } name='lastName' onChange={changeText} />
					</div>
				</div>


			</div>

			<div className="pver-2 b-top b-grey300">
				<h6 className="f-500 f-playfair f-uppercase mb">
					Social Media
				</h6>
				<div className="grows f-bold left">
					<div className='mb'>
						<Input value={user?.linkedin }placeholder='linkedin' light label='linkedin' name='linkedin' onChange={changeText} />
					</div>
					<div className='mb'>
						<Input value={user?.facebook } placeholder='facebook' light label='facebook' name='facebook' onChange={changeText} />
					</div>
					<div className='mb'>
						<Input value={user?.twitter } placeholder='twitter' light label='twitter' name='twitter' onChange={changeText} />
					</div>
					<div className='mb'>
						<Input value={user?.instagram} placeholder='instagram' light label='instagram' name='instagram' onChange={changeText} />
					</div>
				</div>
			</div>
			<div className="flex pver-2 b-top b-grey300">

				<div className="grid2 mver w-100 col-gap">
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							Favorite Dish
						</h6>
						<div>
							<Input placeholder='Favorite Dish' value={user?.favoriteDish } name='favoriteDish' onChange={changeText} />
						</div>
					</div>
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							Favorite Drink
						</h6>
						<div>
							<Input placeholder='Favorite Drink' value={user?.favoriteDrink } name='favoriteDrink' onChange={changeText} />
						</div>
					</div>


				</div>

			</div>

			{/*<div className="flex pver-2 b-top b-grey300">
				<h6 className="f-500 mb-half f-playfair f-uppercase">
					Hobbies
				</h6>
				<div className="grows flex wrap justify-end">
					{user?.hobbies.map(
						({title, id}, i)=>{
							return(
								<span key={id}>
                                    {title}{ i+1 < user?.hobbies.length ? ',' : ''} &nbsp;
                                </span>
							)
						}
					)}
				</div>
			</div>

			<div className="flex pver-2 b-top b-grey300">
				<h6 className="f-500 mb-half f-playfair f-uppercase">
					Favorite Spirits
				</h6>
				<div className="grows flex wrap justify-end">
					{user?.favoriteSpirits.map(
						({title, id}, i)=>{
							return(
								<span key={id}>
                                    {title}{ i+1 < user?.favoriteSpirits.length ? ',' : ''} &nbsp;
                                </span>
							)
						}
					)}
				</div>
			</div>*/}

			<div className="pver-2 mb-2 b-top b-grey300">

				<div className="grid2 mver-2 w-100 col-gap">
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							Industry
						</h6>
						<div>
							<Input placeholder='industry' value={user?.industry } name='industry' onChange={changeText} />
						</div>
					</div>
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							Title
						</h6>
						<div>
							<Input placeholder='Job Title' value={user?.jobTitle } name='jobTitle' onChange={changeText} />
						</div>
					</div>


				</div>

				<div className="grid3 mver-2 w-100 col-gap">
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							City
						</h6>
						<div>
							<Input placeholder='city' value={user?.city } name='city' onChange={changeText} />
						</div>
					</div>
					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							State
						</h6>
						<div>
							<Input placeholder='state' value={user?.state } name='state' onChange={changeText} />
						</div>
					</div>

					<div>
						<h6 className="f-500 mb-half f-playfair f-uppercase">
							Zip Code
						</h6>
						<div>
							<Input placeholder='Zip Code' value={user?.zipCode } name='zipCode' onChange={changeText} />
						</div>
					</div>


				</div>

			</div>
			<div className="center pver-2 mb-2 b-top b-grey300">
				<Button onClick={save}>
					Save Profile
				</Button>
			</div>
		</div>
	)
}