import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import '../Components/UI/Styles/index'
import Header from "../Components/Header";

import Login from "../Containers/Login";
import Home from "../Containers/Home";
import {getMe} from "../Recoil/api/user";
import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import Sidebar from "../Components/Sidebar";
import Guest from "../Containers/Guest";
import Invitations from "../Containers/Invitations";
import Admins from "../Containers/Admins";

export default function Routes() {

    const [session] = useState(window.localStorage.getItem('jwt'));
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useRecoilState(userAtom);

    useEffect(async()=>{

        if (session){
            try {
                const res = await getMe();
                setUser({...user, ...res.data});
                setLoading(false);
            }
            catch (e){
                setLoading(false);
            }
        }
        else {
            console.log("S", session);
            setLoading(false);
        }

    },[session] );

    return (
      <Router>
          <div className="grows direction-column flex black mobile:max-w-100">
              {loading ?
                <div className="flex grows justify-center align-center">
                    Loading
                </div>
              :
                <>

                    <div className="flex grows direction-column">

                        <Switch>
                            <Route exact path="/login">

                                <Header />
                                <Login />

                            </Route>
                            <Route exact path="/">
                                <div className="flex grows mobile:block">
                                    <Sidebar />
                                    <Home />
                                </div>
                            </Route>
                            <Route path="/user/:id">
                                <div className="flex grows mobile:block">
                                    <Sidebar />
                                    <Guest />
                                </div>
                            </Route>
                            <Route path="/invitations">
                                <div className="flex grows mobile:block">
                                    <Sidebar />
                                    <Invitations />
                                </div>
                            </Route>
                            <Route path="/admins">
                                <div className="flex grows mobile:block">
                                    <Sidebar />
                                    <Admins />
                                </div>
                            </Route>
                        </Switch>

                    </div>
                </>
              }

          </div>
      </Router>
    );
}
