import React from "react";
import {useRecoilValueLoadable} from "recoil";
import {genders, interests, spirits} from "../../Recoil/selectors";
import Select from "../UI/Select";

export default function Drinks({onSelect, value}) {

    const values = useRecoilValueLoadable(spirits);

    switch (values.state) {
        case 'hasValue':
            return (
                <Select className="mb mr" label="Spirits" name="favoriteSpirits" value={value} options={values.contents} onChange={onSelect} />
            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error Loading</div>
            )
    }
}
