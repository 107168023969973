import React, { useState } from "react";
import Button from "../UI/Button";
import IconX from "../UI/Icons/IconX";
import InvitationRun from "./InvitationRun";

export default function InvitationBulk({setPop, types, setLoading}) {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setArray([]);
    };

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const array = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header.trim()] = values[index].trim();
                return object;
            }, {});
            return obj;
        });
        setArray(array);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    };

    const headerKeys = Object.keys(Object.assign({}, ...array));

    return (
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90">

            </div>
            <div className="relative z-100 white max-w-800px w-100 p-2 br">
                <div className="right top-2 right-2 absolute">
                    <a onClick={()=>setPop(false)}>
                        <IconX />
                    </a>
                </div>
                <h3 className="mb-2">Bulk Import </h3>
                <p className="mt mb-half">Select a CSV file to import users</p>
                <form className="pb b-bottom b-grey200">
                    <input
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                    />

                    {file &&
                    <Button className="ml-2" height="h-2rem" color="black"
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                    >
                        Preview CSV
                    </Button>
                    }
                </form>

                <br />
                {array.length > 0 &&
                    <h5 className="mb">Total users to import: {array.length}</h5>
                }
                <table>
                    <thead>
                    <tr key={"header"}>
                        {headerKeys.map((key) => (
                            <th className="f-small left pr pb-half b-bottom b-grey300" id={key} key={key}>{key}</th>
                        ))}
                    </tr>
                    </thead>

                    <tbody>
                    {array.length > 3 ?
                        <>
                        {array.slice(0,3).map((item, i) => {
                            return (
                                <tr key={item.email}>
                                    {Object.values(item).map((val) => (
                                        <td className="b-bottom b-grey200 pver-half f-small" id={`${item.email}${val}${i}`} key={`${item.email}${val}${i}`}>{val}</td>
                                    ))}
                                </tr>
                            )
                        })}
                        </>
                    :
                        <>
                        {array.map((item, i) => {
                            return (
                                <tr key={item.email}>
                                    {Object.values(item).map((val) => (
                                        <td className="b-bottom b-grey200 pver-half f-small" id={`${item.email}${val}${i}`} key={`${item.email}${val}${i}`}>{val}</td>
                                    ))}
                                </tr>
                            )
                        })}
                        </>
                    }

                    </tbody>
                </table>

                <InvitationRun invites={array} types={types} setLoading={setLoading} setPop={setPop} />

            </div>
        </div>
    );
}

