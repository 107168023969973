import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import Input from "../Components/UI/Input";
import Button from "../Components/UI/Button";
import Title from "../Components/UI/Title";

import {useRecoilState, useRecoilValue} from "recoil";
import {loginRedirectAtom, userAtom} from "../Recoil/atoms";
import {login} from "../Recoil/api/user";
import Error from "../Components/UI/Error";


export default function Login (){

    const [user, setUser] = useRecoilState(userAtom);
    const [loginRedirect, setLoginRedirect] = useRecoilState(loginRedirectAtom);

    console.log("Redirect", loginRedirect, window.redirect);

    let history = useHistory();

    const [newUser, setNewUser] = useState({
        identifier : "",
        password : ""
    });
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const submit = async (e) => {
        setError("");
        e.preventDefault();

        try {
            const su = await login(newUser);
            setUser(su.data.user);
            window.localStorage.setItem('jwt', su.data.jwt);
            console.log("Taking the user to", loginRedirect);
            window.location.replace(loginRedirect);
        }
        catch (e){
            console.log("SETTING ERROR---", e.response?.data?.message[0]?.messages[0]?.message);
            setError(e.response?.data?.message[0]?.messages[0]?.message);
        }

    }


    return(
        <div className="mobile:phor">

            {user.id && history.push('/')}

            <div className="max-w-500px mhor-auto center pver-3">

                <Title title="Login" />

                <p className="f-500 mb-half f-playfair t-white f-uppercase">
                    Log in here if you are a park administrator
                </p>
            </div>

            <div className="max-w-600px mhor-auto">


                <Input label="Email" className="mb-2" name="identifier" onChange={updateUser} />

                <Input label="Password" type="password" className="mb-2" name="password" onChange={updateUser} />

                <div className="flex justify-center">
                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={submit}>
                        Login
                    </Button>
                </div>

                {error && <Error msg={error} className="t-red center mt" /> }



            </div>



        </div>
    )
}
