/* global _cio */

export function cioIdentify(data){

    let d = {...data};
    d.id = d.email;
    d.phoneNumber = `+1${d.phone}`
    let ca = new Date()
    d.created_at = Math.floor(ca.getTime() / 1000);
    delete d.createdAt;
    delete d.updatedAt;
    delete d.published_at;

    if(d.guest){
        d.guestInviting = data.guest.email;
        delete d.guest;
    }

    if(d.host){
        d.hostInviting = data.host.email;
        delete d.host;
    }

    if(d.type){
        d.inviteType = data.type.label;
        delete d.type;
    }
    d.token = data.id;
    d.inviteCompleted = d.completed;
    delete d.completed;

    _cio.identify(d);
}

export function cioEvent(email, name){
    _cio.identify({id : email});
    _cio.track(name);
}
