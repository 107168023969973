import React, {useState} from "react";
import {maskDate} from "../../Utils/tools";
import Button from "../UI/Button";
import {useRecoilState, useRecoilValue} from "recoil";
import {cloudinaryUrl, userAtom} from "../../Recoil/atoms";
import {updateUser} from "../../Recoil/api/user";
import {Link} from "react-router-dom";

export default function Checkin({user, checkin, setUser}){

    const url = useRecoilValue(cloudinaryUrl);
    const [use, setUse] = useRecoilState(userAtom);

    const verifyId = async (checked) => {

        try {
            await updateUser(user.id, {idChecked : checked});
            setUser({...user, idChecked : checked})
        }
        catch (e){
            console.log(e);
        }
    }

    return(
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90">

            </div>
            <div className="relative z-100 white max-w-400px w-100 p-2 br mobile:mhor">
                <div className="pb mb b-bottom b-grey300 flex">
                    <h5 className="grows center">Guest</h5>
                </div>
                <div className="flex align-center justify-center direction-column w-100">
                    <div className="h-200px w-200px grey200 br-2 mb mhor-auto overflow-hidden shrinks-0">
                        <img src={user.userPhoto ? `${url}${user.userPhoto}.jpg` : user.profilePhoto?.url} className="h-200px w-200px obj-cover" alt="User" />
                    </div>
                    <div className="w-100">
                        <h4 className="mb center">
                            {user?.firstName} {user?.lastName}
                        </h4>

                        <div className="center">
                            <div className="mb">
                                <h6 className="f-500 mb-half f-playfair f-uppercase">
                                    Member Since
                                </h6>
                                <p className="f-700">
                                    {maskDate(user?.createdAt)}
                                </p>
                            </div>
                            <div className="mb">
                                <h6 className="f-500 mb-half f-playfair f-uppercase">
                                    Member ID
                                </h6>
                                <p className="f-1rem f-uppercase f-700">
                                    {user?.restaurantId}{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
                                </p>
                            </div>

                        </div>

                        <div>
                            <p className="f-700 f-small center mb-half mt pt b-top b-grey300">ID Verified? </p>
                            <div className="b b-grey300 flex mb ">
                                <a
                                    className={`b-right b-grey300 p center grows cursor-pointer ${user.idChecked ? "green t-black" : "white t-black"}`}
                                    onClick={()=>verifyId(true)}
                                >
                                    YES
                                </a>
                                <a
                                    className={`grows center p cursor-pointer ${!user.idChecked ? "red t-white" : "white t-black"}`}
                                    onClick={()=>verifyId(false)}
                                >
                                    NO
                                </a>
                            </div>
                        </div>


                        {user.blocked ?
                            <p className="mb center">
                                ⚠️ The guest is blocked. Please unblock this guest from the "Users" tab before checkin.
                            </p>
                        :
                            <Button onClick={()=> {checkin(user.id)}} fontSize="f-small" className="relative phor-2 hover:white hover:t-gold transition-5 w-100 mb" height="h-3rem">
                                Check In
                            </Button>
                        }
                        <p className="center">
                            <Link to={`/user/${user.id}`} fontSize="f-small" color="black" className="relative t-black f-700 block centerphor-2 hover:white hover:b-black hover:t-black transition-5 w-100" height="h-3rem">
                                View Profile
                            </Link>
                        </p>
                    </div>

                </div>
            </div>

        </div>
    )
}
