import React from "react";

export default function IconTt({className, color}){
    return(
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M24.3376 5.99012C24.3376 6.24712 24.3376 6.50409 24.3376 6.76109C24.3376 14.7677 18.976 24 9.1525 24C6.23642 23.9932 3.38967 23.0427 0.981689 21.2719C1.40158 21.3343 1.82467 21.3673 2.24853 21.3707C4.67112 21.3672 7.01615 20.451 8.87704 18.7809C7.74617 18.7149 6.66176 18.2752 5.77135 17.5217C4.88094 16.7682 4.22789 15.7375 3.90112 14.57C4.22842 14.6332 4.5602 14.6663 4.89272 14.6688C5.37178 14.6786 5.84922 14.6052 6.30659 14.4514C5.06738 14.1462 3.9643 13.3889 3.18454 12.3079C2.40478 11.2269 1.99618 9.88885 2.0282 8.5206C2.76487 8.98844 3.59753 9.25324 4.45206 9.29157C3.70909 8.71311 3.105 7.95222 2.68986 7.07204C2.27472 6.19186 2.06041 5.21777 2.06496 4.23062C2.0686 3.16551 2.32123 2.11856 2.79947 1.18618C4.09588 3.04226 5.75383 4.57032 7.65704 5.66319C9.56025 6.75605 11.6626 7.3872 13.8164 7.51234C13.7265 7.06423 13.6835 6.60668 13.688 6.14825C13.6533 4.58517 14.1932 3.07059 15.1904 1.93399C16.1876 0.797387 17.5613 0.130773 19.0127 0.0791016C19.7557 0.0862024 20.4884 0.266559 21.1604 0.607845C21.8324 0.949131 22.4277 1.44324 22.9053 2.05604C24.1091 1.7885 25.2593 1.29314 26.3024 0.593064C25.9108 1.97109 25.078 3.15495 23.9521 3.93409C25.0142 3.79341 26.0489 3.4732 27.0183 2.9852C26.2883 4.14229 25.382 5.15822 24.3376 5.99012Z" className={color ? color : 'black'} />
        </svg>

    )
}
