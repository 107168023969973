import React from "react";
import {useRecoilValueLoadable} from "recoil";
import {genders} from "../../Recoil/selectors";
import Select from "../UI/Select";

export default function Genders({onSelect, value}) {

    const gendersValue = useRecoilValueLoadable(genders);

    switch (gendersValue.state) {
        case 'hasValue':
            return (
                <Select className="mb mr" label="Gender" name="gender" value={value} options={gendersValue.contents} onChange={onSelect} />
            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error</div>
            )
    }
}
