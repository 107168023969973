import React, {useState} from "react";
import {createAdmin} from "../../Recoil/api/data";
import Input from "../UI/Input";
import Button from "../UI/Button";
import IconX from "../UI/Icons/IconX";
import Error from "../UI/Error";
import Select from "../UI/Select";

export default function AdminForm({setPop, setLoading}){

    const [adminTypes, setadminTypes] = useState([
        {
            id: '615502d4ef399c6c60fc5479',
            title: 'Master Admin'
        },
        {
            id: '624ee7faed5731c01c29bf86',
            title: 'Host'
        }
    ])

    const [error, setError] = useState("")
    const [refer, setRefer] = useState(
        {
            "firstName": "",
            "lastName": "",
            "email": "",
            "role" : "",
            "password" : "",
            "sent" : true
        }
    );

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setRefer({...refer, [name] : val});
    }

    const saveAdmin = async () => {

        if(!refer.firstName){
            setError("Please add First Name")
            return
        }
        if(!refer.lastName){
            setError("Please add Last Name")
            return
        }
        if(!refer.email){
            setError("Please add Email")
            return
        }
        if(!refer.password){
            setError("Please add a user password")
            return
        }

        if(!refer.role){
            setError("Please select a role for this user")
            return
        }

        try{
            const res = await createAdmin(refer);
            setLoading(true);
            setPop(false);
            console.log("Admin user", res);

        }
        catch (e){
            //throw e
            console.log("SMS, ERROR", e);
        }
    }

    const changeSelect = (e) => {
        setRefer({...refer, [e.target.name] : e.target.value})
    }

    return(
        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
            <div className="absolute edges black90">

            </div>
            <div className="relative z-100 white max-w-400px w-100 p-2 br">
                <div className="right">
                    <a onClick={()=>setPop(false)}>
                        <IconX />
                    </a>
                </div>
                <div className="pb mb b-bottom b-grey300 flex">
                    <h5 className="grows center">New Admin</h5>
                </div>
                <div>
                    <div className="mb grid2 col-gap mobile:block mobile:mb">
                        <Input light={true} label="First Name" className="mobile:mb" name="firstName" value={refer.firstName} onChange={changeText} />
                        <Input light={true} label="Last Name" name="lastName" value={refer.lastName} onChange={changeText} />
                    </div>

                    <Input light label="Email" className="mb" name="email" value={refer.email} onChange={changeText} />

                    <Input light label="Password" className="mb" name="password" value={refer.password} onChange={changeText} />

                    <Select
                        label="Admin Role"
                        options={adminTypes}
                        className="mb"
                        value={refer.role}
                        name="role"
                        onChange={changeSelect}
                    />

                    {error &&
                        <Error className="mt-2 mb f-1rem t-red" msg={error} />
                    }

                    <div className="flex justify-center">
                        <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveAdmin}>
                            Save Invitation
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
