import React from "react";

import logoWhite from './UI/Styles/img/logo-white.png'
import Button from "./UI/Button";
import {Link, NavLink} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userAtom} from "../Recoil/atoms";

export default function Sidebar(){

    const u = useRecoilValue(userAtom);

    const logout = () => {
        window.localStorage.clear();
        window.location="/"
    }

    return(
        <aside className="mobile:relative black p-2 flex direction-column max-w-200px grows sticky max-h-100vh top-0 mobile:max-w-100 mobile:direction-row mobile:align-center mobile:z-1000 mobile:p">
            <div className="center mobile:grows mobile:left">
                <Link to="/">
                    <img src={logoWhite} className="w-100px mb-2 mobile:w-80px mobile:mb-0" />
                </Link>
            </div>
            <p className="t-white mb-2 center mobile:mr-half mobile:mb-0">
                Hi, <strong>{u.firstName}</strong>
            </p>
            <div className="grows b-top b-grey800 mobile:flex mobile:grey900 mobile:w-100 mobile:left-0 mobile:top-4half mobile:absolute mobile:justify-center">
               {/* <NavLink exact to="/" className="block f-playfair t-white pver-2 h6 op-50 b-bottom b-grey800" activeClassName="op-100">
                    Users
                </NavLink>*/}
                <NavLink to="/" activeClassName="op-100" exact className="mobile:pver-half mobile:phor block f-playfair t-white pver h6 op-50 b-bottom b-grey800">
                    Users
                </NavLink>
                <NavLink to="/invitations" activeClassName="op-100" exact href="/invitations" className="mobile:pver-half mobile:phor block f-playfair t-white pver h6 op-50 b-bottom b-grey800">
                    Invitations
                </NavLink>

                {u?.role?.id === '615502d4ef399c6c60fc5479' &&
                    <NavLink to="/admins" activeClassName="op-100" exact href="/admins"
                             className="mobile:pver-half mobile:phor block f-playfair t-white pver h6 op-50 b-bottom b-grey800">
                        Admins
                    </NavLink>
                }

                {/*<NavLink to="/referred-users" activeClassName="op-100" exact href="/invitations" className="block f-playfair t-white pver h6 op-50 b-bottom b-grey800">
                    Referred Users
                </NavLink>*/}

                {/*<NavLink to="/genders" className="block f-playfair t-white pver-2 h6 op-50 b-bottom b-grey800" activeClassName="op-100">
                    Genders
                </NavLink>
                <NavLink to="/hobbies" className="block f-playfair t-white pver-2 h6 op-50 b-bottom b-grey800" activeClassName="op-100">
                    Hobbies
                </NavLink>*/}
            </div>
            <div>
                <Button className="phor-2 hover:white hover:t-gold transition-5 w-100 b-bottom b-grey800" height="h-2rem" onClick={logout}>
                    Logout
                </Button>
            </div>



        </aside>
    )
}
