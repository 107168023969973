import React from "react";
import Select from "../UI/Select";
import Input from "../UI/Input";
import Button from "../UI/Button";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import {
    boolOptionsAtom,
    monthsAtom,
    orderOptionsAtom,
    statesAtom,
    statusOptionsAtom,
    usersFiltersParams
} from "../../Recoil/atoms";
import Genders from "../Form/Genders";
import Hobbies from "../Form/Hobbies";
import Drinks from "../Form/Drinks";
import InviterAdmins from "../Form/InviterAdmins";

export default function UsersFilters({setLoading}){

    const orderOptions = useRecoilValue(orderOptionsAtom);
    const states = useRecoilValue(statesAtom);
    const months = useRecoilValue(monthsAtom);
    const statuses = useRecoilValue(statusOptionsAtom);
    const boolOptions = useRecoilValue(boolOptionsAtom);
    const [filters, setFilters] = useRecoilState(usersFiltersParams);

    const changeSelect = (e)=>{
        setFilters({...filters, [e.currentTarget.name] :  e.currentTarget.value});
        console.log({...filters, [e.currentTarget.name] :  e.currentTarget.value})
    }

    const changeText = (e) => {
        setFilters({...filters, [e.currentTarget.name] :  e.currentTarget.value});
    }

    const resetFilters = () => {
        setFilters({
            _sort : 'restaurantId:DESC',
            profileCompleted : true,
            firstName_contains : "",
            restaurantId : "",
            restaurantId_gt : 0,
            "status.approved" : "",
            idChecked : "",
            redFlag : "",
            phoneNumber: "",
            email : "",
            gender : "",
            city : "",
            state : "",
            hobbies : "",
            favoriteSpirits : "",
            invitingUser : "",
            birthMonth : ""
        })
        setLoading(true)
    }

    return(
        <div className="mb-2 pb-2 b-bottom b-grey300 mobile:grid1">
            <div className="flex align-end wrap">
                <Select className="mr mb" label="Sort" value={filters.order} name="_sort" options={orderOptions} onChange={changeSelect} />
                <Input light="true" label="First name" className="mr mb max-w-200px w-100" placeholder="First Name" name="firstName_contains" value={filters.firstName_contains} onChange={changeText} />
                <Input light="true" label="Guest Id" className="mr mb max-w-200px w-100" placeholder="Guest Id" name="restaurantId" value={filters.restaurantId} onChange={changeText} />

                <Input light="email" label="Email" className="mr mb max-w-200px w-100" placeholder="Email" name="email" value={filters.email} onChange={changeText} />
                <Input light="true" label="Phone Number" className="mr mb max-w-200px w-100" placeholder="Phone Number" name="phoneNumber" value={filters.phoneNumber} onChange={changeText} />


                <Select label="ID checked?" className="mr mb" unselected="Id Checked?" name="idChecked" value={filters.idChecked} options={boolOptions} onChange={changeSelect} />
                <Select label="Red Flag?" className="mr mb" unselected="Red flag?" name="redFlag" value={filters.redFlag} options={boolOptions} onChange={changeSelect} />

                <Input light="true" label="City" className="mr mb max-w-200px w-100" placeholder="City" name="city" value={filters.city} onChange={changeText} />
                <Select label="State" className="mr mb" unselected="Select State" name="state" value={filters.state} options={states} onChange={changeSelect} />
                <Genders value={filters.gender} onSelect={changeSelect} />
                <Select label="Status" className="mr mb" unselected="Select Status" name="status.approved" value={filters["status.approved"]} options={statuses} onChange={changeSelect} />

                <Hobbies value={filters.hobbies} onSelect={changeSelect} />
                <Drinks value={filters.favoriteSpirits} onSelect={changeSelect} />

                <Select label="Month of Birth" className="mr mb" unselected="Select Month" name="birthMonth" value={filters.birthMonth} options={months} onChange={changeSelect} />

                <InviterAdmins value={filters.invitingUser} onSelect={changeSelect} />

                <div>
                    <Button className="mr mb" color="black" onClick={()=>{setLoading(true)}}>
                        Search
                    </Button>
                </div>
            </div>

            <a className="f-underline" onClick={resetFilters}>Reset all filters</a>
        </div>

    )
}