import React, {useState} from 'react'

export default function Input({label, children, light, className, width, ...props}){

    const [defaultClass] = useState(`f-400 h-3rem phor b b-grey500 br-half f-1rem ${width ? width : "w-100"}`)

    return(
        <div className={className}>
            {label &&
                <label className={`f-small mb-half block f-700 ${light ? "t-black" : "t-white"}`}>{label}</label>
            }
            <div className="relative">
                <input className={defaultClass} placeholder={label} {...props} />
                {children && children}
            </div>

        </div>
    )

}
