import React from "react";

export default function IconIn({className, color}){
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect width="24" height="24" rx="3" fill="black"/>
            <g clipPath="url(#clip0)">
                <path d="M13.307 9.05174C13.5434 8.80931 13.7922 8.57894 14.0521 8.36153C14.5733 8.00937 15.1851 7.81264 15.8153 7.79457C16.4514 7.72956 17.0942 7.79671 17.7027 7.99176C18.0755 8.11886 18.4189 8.31838 18.7131 8.57867C19.0074 8.83896 19.2463 9.15482 19.4162 9.5078C19.7548 10.195 19.9333 10.9491 19.9379 11.714C19.9379 13.5875 19.9379 15.4117 19.9379 17.3221C19.9379 17.396 19.9379 17.47 19.9379 17.5439C19.9232 17.6472 19.8772 17.7436 19.8063 17.8206C19.7354 17.8976 19.6428 17.9515 19.5405 17.9753H19.3543H17.2307H17.057C16.9397 17.9573 16.833 17.8971 16.7575 17.8062C16.682 17.7153 16.6427 17.6001 16.6471 17.4823V12.7001C16.6539 12.2195 16.552 11.7436 16.3491 11.3073C16.237 11.0767 16.0601 10.8831 15.8398 10.7498C15.6196 10.6164 15.3653 10.5489 15.1074 10.5554C14.833 10.5215 14.5545 10.5634 14.3026 10.6765C14.0506 10.7896 13.8351 10.9695 13.6796 11.1964C13.4949 11.4634 13.368 11.7656 13.307 12.0838C13.2535 12.3352 13.2244 12.5911 13.22 12.848C13.22 14.364 13.22 15.8677 13.22 17.3837C13.2296 17.4659 13.2206 17.5492 13.1936 17.6274C13.1666 17.7057 13.1225 17.777 13.0642 17.8361C13.0058 17.8952 12.9348 17.9406 12.8565 17.9689C12.7782 17.9973 12.6945 18.0079 12.6116 18H10.6001C10.5146 18.0099 10.4277 18.0004 10.3464 17.9723C10.265 17.9442 10.1911 17.8982 10.1302 17.8377C10.0693 17.7772 10.023 17.7039 9.99472 17.6231C9.96641 17.5424 9.95689 17.4563 9.96683 17.3714C9.96683 14.4256 9.96683 11.4798 9.96683 8.53408C9.96133 8.45336 9.97337 8.37241 10.002 8.29666C10.0306 8.22091 10.075 8.15213 10.1327 8.09492C10.1903 8.03771 10.2598 7.9934 10.3361 7.96498C10.4124 7.93656 10.4939 7.92469 10.5753 7.93015C11.2706 7.93015 11.9783 7.93015 12.6737 7.93015C12.7453 7.92674 12.8168 7.938 12.8838 7.96327C12.9508 7.98853 13.012 8.02724 13.0633 8.07698C13.1145 8.12671 13.1548 8.18641 13.1818 8.25233C13.2088 8.31826 13.2218 8.389 13.22 8.46013L13.307 9.05174Z"  className={color ? color : 'white'} />
                <path d="M5.4593 12.9466V8.5341C5.44665 8.45191 5.45381 8.36792 5.47991 8.28891C5.50602 8.2099 5.55043 8.1381 5.60966 8.07931C5.66889 8.02051 5.74106 7.97639 5.82066 7.95048C5.90025 7.92457 5.98492 7.91761 6.06773 7.93017H8.11675C8.18789 7.92516 8.25933 7.93434 8.32684 7.95719C8.39434 7.98004 8.4564 8.01606 8.50964 8.06317C8.56288 8.11027 8.60606 8.16749 8.63666 8.23144C8.66726 8.29538 8.68476 8.36477 8.6879 8.43549C8.69406 8.50932 8.69406 8.58353 8.6879 8.65736V17.433C8.68968 17.5074 8.67612 17.5813 8.64788 17.6502C8.61964 17.7192 8.57716 17.7816 8.52359 17.8336C8.47001 17.8856 8.40626 17.9261 8.33624 17.9526C8.26621 17.9792 8.19162 17.9911 8.11675 17.9877C7.42139 17.9877 6.71338 17.9877 6.01801 17.9877C5.93867 17.9918 5.85951 17.9787 5.7858 17.9492C5.71208 17.9198 5.64585 17.8748 5.59147 17.8173C5.53709 17.7598 5.49576 17.6912 5.47082 17.6163C5.44588 17.5414 5.43773 17.4621 5.44687 17.3837L5.4593 12.9466Z"  className={color ? color : 'white'} />
                <path d="M5 5.05834C5.00326 4.51343 5.22267 3.99175 5.61086 3.60643C5.99904 3.22112 6.52461 3.00323 7.07358 3C7.62798 3.00326 8.15847 3.22414 8.54934 3.61443C8.9402 4.00471 9.1596 4.53266 9.15959 5.08298C9.14987 5.62686 8.92643 6.1454 8.5366 6.52773C8.14678 6.91007 7.62159 7.12586 7.07358 7.12899C6.52247 7.12574 5.99515 6.90615 5.60661 6.51818C5.21808 6.13021 4.99999 5.60538 5 5.05834V5.05834Z"  className={color ? color : 'white'} />
            </g>
        </svg>

    )
}
