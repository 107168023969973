import axios from "axios";
import {url} from "../atoms";

export const login = async (data) => {

    try {
        const res = await axios.post(`${url}auth/local`, data, {withCredentials : true});
        console.log(res);
        return (res);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getMe = async () => {

    try {
        const res = await axios.get(
            `${url}users/me`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}

export const getUsers = async (par) => {

    let params = {...par}
    for (const item in params) {
        if(!params[item]){
            delete params[item]
        }
    }
    
    try {
        const res = await axios.get(
            `${url}users`,
            {
                withCredentials : true,
                params : params,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const getAdmins = async () => {

    let params = {
        role : "615502d4ef399c6c60fc5479"
    }


    try {
        const res = await axios.get(
            `${url}users`,
            {
                withCredentials : true,
                params : params,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}


export const getAllAdmins = async () => {

    let params = {
        '_where': {
            '_or': [
                {'role' : "615502d4ef399c6c60fc5479"},
                {'role' : "624ee7faed5731c01c29bf86"}
            ]
        }
    }


    try {
        const res = await axios.get(
            `${url}users?_where[_or][0][role]=615502d4ef399c6c60fc5479&_where[_or][1][role]=624ee7faed5731c01c29bf86&_where[_or][2][role]=624ee7dfed5731c01c29bee6&_sort=lastName:ASC`,
            {
                withCredentials : true,
                //params : {params},
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        throw e
    }

}


    //?_where[_or][0][0][stars]=2


export const updateUser = async (id, data) => {
    try {
        const res = await axios.put(
            `${url}users/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}

export const uploadPhoto = async (data) => {
    try {
        const res = await axios.post(
            `${url}upload`,
            data,
            {
                withCredentials : true,
                headers : {
                    'Authorization' : `Bearer ${window.localStorage.getItem('jwt')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}


export const getUser = async (id) => {

    try {
        const res = await axios.get(
            `${url}users/${id}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}
