import React from "react";

export default function IconOptions({className, color}){
    return(
        <svg width="7" height="32" viewBox="0 0 7 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <ellipse cx="3.31682" cy="3" rx="2.9406" ry="3" className={color ? color : "black"}/>
            <ellipse cx="3.31682" cy="16" rx="2.9406" ry="3" className={color ? color : "black"}/>
            <ellipse cx="3.31682" cy="29" rx="2.9406" ry="3" className={color ? color : "black"}/>
        </svg>

    )
}
