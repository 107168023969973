import React, {useState} from "react";
import IconOptions from "../UI/Icons/IconOptions";
import {deleteUser, updateUser, updateUserStatus} from "../../Recoil/api/data";
import {useRecoilState, useRecoilValue} from "recoil";
import {loadingUsers, userAtom} from "../../Recoil/atoms";
import {cioEvent, cioIdentify} from "../../CIO/cio";

export default function UserActions({u, approve, block, remove}){

    const host = useRecoilValue(userAtom);

    const [options, setOptions] = useState(false);
    const [loading, setLoading] = useRecoilState(loadingUsers);

    const action = async (a) => {
        setOptions(false);
        let val = false;

        if(a === 'approve') {
            if (u.status.approved) {
                val = false;
            } else {
                val = true;
            }
            await saveStatus(val);

            if(val){
                /*cioIdentify(u.email);*/
                cioEvent(u.email, "approved");
            }
            else{
                console.log("NOT APPROVED")
            }
        }
    }

    const saveStatus = async (value) => {

        await updateUserStatus(u.status.id, value);
        console.log("Status updated")
        await updateUser(u.id, {approvedBy : host.firstName});
        console.log("User updated", u.id, host.firstName)
        setLoading(true);

    }

    const saveUser = async (us)=>{
        try {
            const user = await updateUser(u.id, us);
            setLoading(true);
            console.log(user);
        }
        catch (e){
            console.log(e);
        }
    }

    const removeUser = async ()=>{
        try {
            const del = await deleteUser(u.id);
            console.log(del);
            setLoading(true);
        }
        catch (e){
            console.log(e);
        }
    }

    return(
        <div className="relative hover-child-block">
            <a className="phor block">
                <IconOptions className="h-1rem" />
            </a>
            <div className={`absolute top-1 p b b-grey300 white z-500 hidden`}>
                <a className="block f-small pver-quarter hover:grey100" onClick={()=>{action('approve')}}>
                    {u.status?.approved ?
                        "Disapprove"
                        :
                        "Approve"
                    }
                </a>
                <a className="block f-small pver-quarter hover:grey100" onClick={removeUser}>
                    Delete
                </a>
            </div>
        </div>
    )
}
