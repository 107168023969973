import React from "react";

export default function Title({title, light}){
    return(
        <div className="flex justify-center align-center relative">
            <h1 className={`${light ? "white t-black" : "black t-white"} h3 f-uppercase mb-2 f-500 phor-3 relative z-500 mobile:h4`}>
                {title}
            </h1>
            <div className="absolute top-1 h-halfrem b-bottom-2 b-gold w-100">

            </div>
        </div>
    )
}
