import {maskDate} from "../../Utils/tools";
import React from "react";

export default function GuestMainData({user}){
	return(
		<div className="grid3 mver-2">
			<div className="b-right b-grey300">
				<h6 className="f-500 mb-half f-playfair f-uppercase mobile:f-1rem">
					JOINED ON
				</h6>
				<p>
					{maskDate(user?.createdAt)}
				</p>
			</div>
			<div className="b-right b-grey300">
				<h6 className="f-500 mb-half f-playfair f-uppercase mobile:f-1rem">
					Birthday
				</h6>
				<p>
					{maskDate(user?.birthday)}
				</p>
			</div>
			<div>
				<h6 className="f-500 mb-half f-playfair f-uppercase mobile:f-1rem">
					Member ID
				</h6>
				<p className="f-1rem f-uppercase">
					{user?.restaurantId}{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
				</p>
			</div>

		</div>
	)
}