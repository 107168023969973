import Button from "../Components/UI/Button";
import React, {useEffect, useState} from "react";
import {getAllAdmins} from "../Recoil/api/user";
import AdminForm from "../Components/Admins/AdminForm";

export default function Admins(){

	const [loading, setLoading] = useState(true);
	const [admins, setAdmins] = useState([]);
	const [pop, setPop] = useState(false);

	useEffect(async () => {

		if(loading){
			await loadAdmins();
			setLoading(false);
		}

	}, [loading]);

	const loadAdmins = async () => {
		try {
			const res = await getAllAdmins();
			console.log(res);
			setAdmins(res);
		}
		catch (e){
			console.log(e);
		}
	}

	const dateFormat = (d)=> {
		let date = new Date(d);
		return date.toLocaleDateString();
	}

	const del = (id)=> {

	}

	return(
		<div className="grows white mobile:pt-2">

			<div className="p-2 mobile:p">

				<div className="mb-4 mobile:mb-2 flex align-center mobile:block">
					<div className="w-100 flex align-center">
						<h4 className='mr-2'>
							Admin Users
						</h4>
						<Button type='a' href='https://thepark-api.herokuapp.com/admin' target="_blank">{/*onClick={()=>setPop(true)}*/}
							+ Edit Admins
						</Button>
					</div>
				</div>

				<table>
					<thead>
					<tr className="b-bottom b-grey300 t-black f-uppercase f-small left f-small">
						<th className="pver">Name</th>
						<th className="pver">Role</th>
						<th className="pver">Email</th>
						<th className="pver">Created At</th>
						<th className="pver right">Actions</th>
					</tr>
					</thead>
					<tbody>

					{admins?.map(
						({firstName, lastName, role, email, createdAt, id})=>{
							return(
								<tr className='b-bottom b-grey300 t-black' key={id}>
									<td className='pver pr'>
										{firstName} {lastName}
									</td>
									<td className='pver pr'>
										{role.name}
									</td>
									<td className='pver pr'>
										{email}
									</td>
									<td className='pver pr'>
										{dateFormat(createdAt)}
									</td>
									<td className='pver right'>
										{role.id !== '615502d4ef399c6c60fc5479' &&
											<Button height="h-2rem" fontSize="f-small" color="black" onClick={() => {
												del(id)
											}}>
												Delete
											</Button>
										}
									</td>
								</tr>
							)
						}
					)}


					</tbody>
				</table>

				{pop &&
					<AdminForm setPop={setPop} setLoading={setLoading} />
				}

			</div>




		</div>
	)
}